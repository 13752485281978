import React, { useState, useContext } from 'react'
import { Grid } from '@mui/material'

import { PageLayout } from '../containers'
import { GenericAccordion } from '../common'
import { PageContext } from '../../utils/helpers'
import { PAGES } from '../../constants'

import { questions } from '../../data'

const Help = () => {
  const { setPage } = useContext(PageContext)
  const [expanded, setExpanded] = useState(null)

  const handleBack = () => setPage(PAGES.MAIN)

  const handleChange = (e) => {
    const { value } = e?.target || e
    setExpanded(value === expanded ? null : value)
  }

  return (
    <PageLayout title='Help' backButton onBack={handleBack} >
      <Grid container spacing={1.5} sx={{ p: 3 }}>
        {questions.map(({ title, text }, idx) => (
          <Grid key={`question-${idx}`} item xs={12}>
            <GenericAccordion
              title={title}
              detailsText={text}
              value={idx}
              expanded={expanded}
              onChange={handleChange}
            />
          </Grid>
        ))}
      </Grid>
    </PageLayout>
  )
}

export default Help
