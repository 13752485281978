import React, { useContext } from 'react'

import { PageContext } from '../utils/helpers'
import { INFO_LABEL, QUEUE_LIMIT_TEXT, PAGES } from '../constants'
import { Layout, MainPage } from '../components/containers'
import { Filters, GenericDialog } from '../components/common'
import { Help } from '../components/help'
import { ConfigureWebCrawl } from '../components/crawlers'
import {
  BuildPredictor,
  SamplesCrawler,
  ViewPredictor,
  TestPredictor,
  MergePredictors,
  MergeConfigurator
} from '../components/predictors'

const pages = {
  [PAGES.MAIN]: MainPage,
  [PAGES.HELP]: Help,
  [PAGES.CRAWL_CONFIGURE]: ConfigureWebCrawl,
  [PAGES.PREDICTOR_BUILD]: BuildPredictor,
  [PAGES.CRAWLERS_SAMPLE]: SamplesCrawler,
  [PAGES.PREDICTOR_VIEW]: ViewPredictor,
  [PAGES.PREDICTOR_EDIT]: BuildPredictor,
  [PAGES.PREDICTOR_TEST]: TestPredictor,
  [PAGES.PREDICTOR_MERGE]: MergePredictors,
  [PAGES.PREDICTORS_MERGE]: MergePredictors,
  [PAGES.PREDICTOR_MERGE_CONFIGURATOR]: MergeConfigurator,
  def: MainPage
}

const App = () => {
  const { page, infoQueue, closeInfoQueue, errorMsg, handleErrorMsg } = useContext(PageContext)
  const Comp = pages[page] || pages.def

  const closeErrorDialog = () => handleErrorMsg(null)

  return (
    <Layout >
      <Comp />
      <Filters />
      <GenericDialog
        open={infoQueue}
        onClose={closeInfoQueue}
        title='Queue Limit Reached'
        text={QUEUE_LIMIT_TEXT}
        submitLabel={INFO_LABEL}
        onSubmit={closeInfoQueue}
      />
      <GenericDialog
        open={!!errorMsg}
        onClose={closeErrorDialog}
        title='Oops, there was a problem...'
        text={errorMsg}
        submitLabel={INFO_LABEL}
        onSubmit={closeErrorDialog}
      />
    </Layout>
  )
}

export default App
