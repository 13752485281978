import React from 'react'

import { WithUser, WithPage } from '../components/containers'
import App from '../components/App'

const IndexPage = () => (
  <WithUser>
    <WithPage>
      <App />
    </WithPage>
  </WithUser>
)

export default IndexPage
